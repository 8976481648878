export const environment = {
  envName: 'test',
  production: true,
  graphQLConfig: {
    apiUrl: 'https://qdpumum3ia.execute-api.us-east-1.amazonaws.com/graphql',
    test: 'https://qdpumum3ia.execute-api.us-east-1.amazonaws.com/',
    downloadItemDetailImagesUrl: 'https://qdpumum3ia.execute-api.us-east-1.amazonaws.com/downloadItemDetailImages/:itemId',
    downloadPayoutReportUrl: 'https://qdpumum3ia.execute-api.us-east-1.amazonaws.com/downloadPayoutReport/:accountId/:payoutId/:reportName',
    downloadInvoiceReportUrl: 'https://qdpumum3ia.execute-api.us-east-1.amazonaws.com/downloadInvoice/:accountId/:invoiceId',
    changePasswordUrl: 'https://qdpumum3ia.execute-api.us-east-1.amazonaws.com/changePassword/:email'
  },
  url: 'https://test.jjkaneportal.com/',
  auth0Config: {
    domain: 'test-login.altec.com',
    clientId: 'NiUerB5WskV5qwBgu8PPBxdWc8hBQOTJ',
    audience: 'jjk-portal-api'
  },
  aimApi: 'https://cysidv7mvg.execute-api.us-east-1.amazonaws.com/dev',
  devToolsEnabled: false,
  jjkImageUrl: 'https://s7d9.scene7.com/is/image/',
  jjkImageNamePrefix: 'JJKane/',
  jjkWaterMark: '?$Thumb_wm$',
  connectStaticsUrl: 'https://atg-dev.altec.com/static/',
  holdingSaleIds: [
    167
  ]
};
